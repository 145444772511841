<template>
	<!-- begin:: Header Topbar -->
	<div class="topbar">
		<router-link v-if="false" :to="{ name: 'checkout' }">
			<span class="fa-stack fa-2x has-badge mr-5">
				<i class="fa fa-circle fa-stack-2x"></i>
				<i class="fa fa-shopping-cart fa-stack-1x fa-inverse"></i>
			</span>
		</router-link>
		<div
			class="notification-template d-flex align-items-center ms-1 ms-lg-3 mr-4"
			style="position: relative"
		>
			<v-menu offset-y left :close-on-content-click="false">
				<template v-slot:activator="{ on, attrs }">
					<div
						v-bind="attrs"
						v-on="on"
						class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
						v-if="false"
					>
						<span class="symbol symbol-30 symbol-light-success">
							<span class="symbol-label font-size-h5 font-weight-bold" style="position: relative">
								<div v-if="notificationLoading">
									<div
										role="progressbar"
										aria-valuemin="0"
										aria-valuemax="100"
										class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate primary--text"
										style="height: 18px; width: 18px"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="22.857142857142858 22.857142857142858 45.714285714285715 45.714285714285715"
											style="transform: rotate(0deg)"
										>
											<circle
												fill="transparent"
												cx="45.714285714285715"
												cy="45.714285714285715"
												r="20"
												stroke-width="5.714285714285714"
												stroke-dasharray="125.664"
												stroke-dashoffset="125.66370614359172px"
												class="v-progress-circular__overlay"
											></circle>
										</svg>
										<div class="v-progress-circular__info"></div>
									</div>
								</div>
								<template v-else>
									<i class="mdi mdi-bell-outline" style="font-size: 22px; color: #1bc5bd"></i>
									<span class="count-badge">{{ notifications.length }}</span>
								</template>
							</span>
						</span>
					</div>
				</template>
				<div class="notification-list">
					<div
						class="menu menu-sub menu-sub-dropdown menu-column"
						style="
							width: 480px;
							box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
								0 1px 18px 0 rgba(0, 0, 0, 0.12) !important;
						"
					>
						<!--begin::Heading-->
						<div class="d-flex flex-column rounded-top" style="background-color: #f6f9fc">
							<!--begin::Title-->
							<h3 class="fw-semobold px-4 mt-3 mb-3 font-level-5 w-100">
								Notifications
								<span class="fs-8 opacity-75 ps-3 font-level-3">({{ notifications.length }})</span>
								<div class="mt-2">
									<v-chip
										:color="all_notification ? 'blue darken-4 white--text' : 'grey lighten-2'"
										v-on:click="
											all_notification = true;
											init_notification();
										"
										small
										>All</v-chip
									>
									<v-chip
										:color="all_notification ? 'grey lighten-2' : 'blue darken-4 white--text'"
										v-on:click="
											all_notification = false;
											init_notification();
										"
										class="ml-2"
										small
										>Unread</v-chip
									>
								</div>
							</h3>
							<!--end::Title-->

							<!--begin::Items-->
							<div class="bg-white" style="max-height: 325px; overflow-y: scroll">
								<div v-if="notificationLoading" class="px-10 py-10">
									<v-progress-linear
										color="blue darken-4"
										indeterminate
										rounded
										height="6"
									></v-progress-linear>
								</div>
								<table v-else-if="notifications.length" width="100%" style="table-layout: fixed">
									<tr v-for="(item, index) in notifications" :key="index">
										<td width="80%" class="py-2 px-4">
											<div class="mb-0 me-2">
												<span
													class="blue--text text--darken-4 fs-7 text-gray-800 text-hover-primary fw-bold cursor-pointer"
													v-on:click="route_to_detail(item)"
													>{{ item.message }}
												</span>
												<div class="text-gray-400 fs-7">
													{{ item.description }}
												</div>
											</div>
										</td>
										<td width="20%" align="center" valign="middle" class="px-4 py-2">
											<v-chip
												class="mb-2"
												v-if="item.is_marked == 0"
												x-small
												v-on:click="mark_as_read(item)"
												color="blue darken-4 white--text"
											>
												Read
											</v-chip>
											<div style="font-size: 11px">{{ formatDateTime(item.datetime) }}</div>
										</td>
									</tr>
								</table>
								<div v-else class="d-flex align-center justify-center flex-column">
									<v-img max-width="200px" :src="$assetURL('media/notification-bell.gif')"></v-img>
									<p class="h1-label py-4" style="text-transform: unset !important">No notifications yet!</p>
								</div>
							</div>
							<!--end::Items-->
							<!--end::Tabs-->
						</div>
					</div>
				</div>
			</v-menu>
		</div>
		<!--begin: User Bar -->
		<KTQuickUser></KTQuickUser>
		<!--end: User Bar -->
	</div>
	<!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
	.dropdown-toggle {
		padding: 0;
		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	.dropdown-menu {
		margin: 0;
		padding: 0;
		outline: none;
		.b-dropdown-text {
			padding: 0;
		}
	}
	.fa-2x {
		font-size: 24px !important;
	}
	.fa-stack {
		display: inline-block;
		height: 50px;
		line-height: 48px;
		position: relative;
		vertical-align: middle;
		width: 50px;
	}
	.fa-stack[data-count]:after {
		position: absolute;
		right: 0%;
		top: 0%;
		content: attr(data-count);
		font-size: 13px;
		padding: 4px;
		border-radius: 50px;
		line-height: 12px;
		color: #1bc5bd;
		text-align: center;
		min-width: 22px;
		min-height: 20px;
		background: white;
		border-style: solid;
		border-width: 1px;
		font-weight: 500;
	}
	.fa-circle {
		color: #c9f7f5;
	}
	.fa-inverse {
		color: #1bc5bd;
	}
}
</style>

<script>
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
//import Pusher from "pusher-js";
import ObjectPath from "object-path";

// Pusher.logToConsole = true;

export default {
	name: "KTTopbar",
	components: {
		KTQuickUser,
	},
	data() {
		return {
			m_status: true,
			pusher: null,
			channel: null,
			notifications: [],
			notificationLoading: true,
			all_notification: false,
		};
	},
	methods: {
		route_to_detail(param) {
			if (param.route) {
				let route = param.route;
				route.query.t = new Date().getTime();
				this.$router.push(param.route).catch(() => {});
			}
		},
		mark_as_read(param) {
			ApiService.patch(`dashboard/${param.id}/notification`, { all: +!!this.all_notification })
				.then((output) => {
					this.notifications = ObjectPath.get(output, "data", []);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				});
		},
		init_notification() {
			this.notificationLoading = true;
			this.notifications = [];
			ApiService.query("dashboard/notifications", { all: +!!this.all_notification })
				.then((output) => {
					this.notifications = ObjectPath.get(output, "data", []);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.notificationLoading = false;
				});
		},
		init_pusher() {
			const _this = this;
			const user = JwtService.getCurrentUser();

			const user_id = ObjectPath.get(user, "id", 0);
			const is_admin = ObjectPath.get(user, "is_admin", 0);
			const is_super_admin = ObjectPath.get(user, "is_super_admin", 0);
			const is_alcon = ObjectPath.get(user, "is_alcon", 0);

			if (is_super_admin == 1) {
				const broadcast_on = `public.event.super-admin`.toString();
				_this.channel = _this.pusher.subscribe(broadcast_on);
				_this.channel.bind(broadcast_on, function (data) {
					_this.notifications.unshift(data);
				});
			} else if (is_admin == 1) {
				const broadcast_on = `public.event.caltek-admin`.toString();
				_this.channel = _this.pusher.subscribe(broadcast_on);
				_this.channel.bind(broadcast_on, function (data) {
					_this.notifications.unshift(data);
				});
			} else if (is_alcon == 1) {
				const broadcast_on = `public.event.alcon`.toString();
				_this.channel = _this.pusher.subscribe(broadcast_on);
				_this.channel.bind(broadcast_on, function (data) {
					_this.notifications.unshift(data);
				});
			} else if (user_id > 0) {
				const broadcast_on = `private.event.${btoa(user_id)}`.toString();
				_this.channel = _this.pusher.subscribe(broadcast_on);
				_this.channel.bind(broadcast_on, function (data) {
					_this.notifications.unshift(data);
				});
			}
		},
	},
	mounted() {
		//this.init_pusher();
		this.init_notification();
	},
	created() {
		//this.pusher = new Pusher(this.$pusherKey, { cluster: "ap1", encrypted: true });
	},
};
</script>
